import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const SectionFour = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: 77px;

  @media (max-width: 1440px) {
    margin-left: 29px;
    margin-right: 29px;
  }

  @media (max-width: 850px) {
    .jornada {
      display: none;
    }
  }

  @media (max-width: 500px) {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

export const SectionFourTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;

    margin-top: 77px;

    color: ${colors.blue400};

    @media (max-width: 850px) {
      margin-top: 0;
    }
  }

  p {
    width: 100%;
    max-width: 486px;

    margin: 24px 0;
  }

  div {
    width: 100%;
    max-width: 544px;

    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 563px) {
    div {
      flex-direction: column;
      max-width: 145px;
      height: 162px;
    }

    p {
      max-width: 338px;
    }
  }
`;

export const SectionFourBottom = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 50px;

  a {
    background-color: ${colors.blue400};

    outline: none;

    border: none;

    border-radius: 8.4px;
    height: 40px;

    width: 100%;

    color: white;

    font-size: 16px;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 263px;

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }
  }
`;
