import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '@/components/Layout';
import BreadCrumbs from '@/components/BreadCrumbs';

import SEO from '@/components/SEO';

import {
  Container,
  Wrapper,
  BreadCrumbsWrapper,
  SectionWrapper,
} from '@/styles/pages/conheca-a-generoso';

import HeroSection from '@/components/Pages/SobreNos/HeroSection';
import SectionFour from '@/components/Pages/SobreNos/SectionFour';
import SectionOne from '@/components/Pages/SobreNos/SectionOne';
import SectionTwo from '@/components/Pages/SobreNos/SectionTwo';
import SectionFive from '@/components/Pages/SobreNos/SectionFive';
import { CMS_URL } from '@/config';

const pageBreadCrumbs = [
  {
    name: 'Página inicial',
    relativeURL: '/',
  },
  { name: 'A Generoso' },
  { name: 'Sobre nós' },
];

const ConhecaAGenerosoPage = (): JSX.Element => {
  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "sobre-nos" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Sobre nós"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />

      <Layout>
        <Wrapper>
          <Container>
            <SectionWrapper>
              <HeroSection />
            </SectionWrapper>

            <BreadCrumbsWrapper>
              <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
            </BreadCrumbsWrapper>

            <SectionWrapper>
              <SectionOne />
            </SectionWrapper>

            <SectionWrapper>
              <SectionTwo />
            </SectionWrapper>

            <SectionWrapper>
              <SectionFour />
            </SectionWrapper>

            <SectionWrapper>
              <SectionFive />
            </SectionWrapper>
          </Container>
        </Wrapper>
      </Layout>
    </>
  );
};

export default ConhecaAGenerosoPage;
