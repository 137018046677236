import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const HeroSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 100%;

  .gatsby-image-wrapper {
    opacity: 1;
    width: 100%;

    background-color: ${colors.blue400};

    img {
      mix-blend-mode: soft-light;
      height: 512px;
    }
  }

  img {
    position: absolute;
  }

  @media (max-width: 1200px) {
    .gatsby-image-wrapper {
      img {
        width: 100%;
        height: 490px;
      }
    }
  }

  @media (max-width: 800px) {
    .gatsby-image-wrapper {
      width: 100%;

      img {
        width: 100%;
      }
    }

    img {
      width: 300px;
    }
  }
  @media (max-width: 690px) {
    margin: 0px 0px;

    .gatsby-image-wrapper {
      img {
        width: 100%;
        height: 400px;
      }
    }
  }

  @media (max-width: 500px) {
    .gatsby-image-wrapper {
      width: 100%;

      img {
        width: 100%;
      }
    }

    img {
      width: 283px;
    }
  }

  @media (max-width: 499px) {
    .gatsby-image-wrapper {
      width: 100%;

      img {
        width: 100%;
      }
    }

    img {
      width: 230px;
    }
  }
`;
