import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const SectionFive = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1380px;

  margin-top: 77px;
  margin-bottom: 126px;

  @media (max-width: 1174px) {
    flex-direction: column;
    align-items: center;
    max-width: 703px;
  }

  @media (max-width: 500px) {
    margin-bottom: 0;
  }
`;

export const SectionFiveLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  max-width: 703px;

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

export const SectionFiveRight = styled.div`
  width: 100%;
  max-width: 678px;

  height: 100%;

  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${colors.blue400};

  /* border-radius: 8.4px; */

  @media (max-width: 1174px) {
    max-width: 703px;
    padding: 52px 0;
  }

  @media (max-width: 673px) {
    justify-content: flex-start;
    padding: 52px 15px;
  }

  @media (max-width: 499px) {
    padding: 20px 15px;
  }
`;

export const SectionFiveRightText = styled.div`
  width: 100%;
  max-width: 421px;

  height: 562px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    color: ${colors.blue200};

    font-size: 48px;
    font-weight: 700;

    width: 100%;
    max-width: 421px;

    line-height: 60px;
  }

  h3 {
    color: ${colors.blue200};

    font-size: 20px;
    line-height: 30px;
  }

  p {
    color: white;
    line-height: 24px;

    width: 100%;

    max-width: 366px;

    font-size: 16px;

    margin-top: 15px;
  }

  @media (max-width: 499px) {
    h2 {
      font-size: 30px;
      line-height: 42px;
    }

    div {
      margin-top: 15px;
    }

    h3 {
      font-size: 15px;
      line-height: 25px;
    }
    p {
      font-size: 10px;
      line-height: 18px;
    }

    height: 100%;
  }
`;
