import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw;

  height: 100%;

  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1380px;

  height: 100%;
  display: flex;
  justify-content: center;
`;

export const BreadCrumbsWrapper = styled.div`
  width: 100%;
  max-width: 1380px;

  justify-content: center;
  display: flex;
  align-items: center;

  margin: 32px 0;

  @media (max-width: 1440px) {
    margin-left: 29px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;
