import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import {
  SectionFourMiddle,
  SectionFourMiddleItem,
  SectionFourMiddleItemBottom,
  SectionFourMiddleItemMiddle,
  SectionFourMiddleItemTop,
} from './styles';

const SectionFourMiddleComponent = (): JSX.Element => {
  return (
    <SectionFourMiddle>
      <SectionFourMiddleItem>
        <SectionFourMiddleItemTop>
          <h3>Conexão Sul</h3>
          <p>mg, sp, go, pr, sc, df e rs</p>
        </SectionFourMiddleItemTop>
        <SectionFourMiddleItemMiddle>
          <StaticImage
            src="../../../../images/paginas/conheca-a-generoso/section-four-item-one.png"
            alt="Imagem de conexão entre Transporte Generoso e Rodonaves"
            quality={100}
            placeholder="blurred"
          />
        </SectionFourMiddleItemMiddle>
        <SectionFourMiddleItemBottom>
          <p>Ponto de conexão</p>
        </SectionFourMiddleItemBottom>
      </SectionFourMiddleItem>
      <SectionFourMiddleItem>
        <SectionFourMiddleItemTop>
          <h3>Conexão Centro-Oeste</h3>
          <p>mt, ro, pa, ac e ms</p>
        </SectionFourMiddleItemTop>
        <SectionFourMiddleItemMiddle>
          <StaticImage
            src="../../../../images/paginas/conheca-a-generoso/section-four-item-two.png"
            alt="Imagem de conexão entre Transporte Generoso e Carvalima"
            quality={100}
            placeholder="blurred"
          />
        </SectionFourMiddleItemMiddle>
        <SectionFourMiddleItemBottom>
          <p>Ponto de conexão</p>
        </SectionFourMiddleItemBottom>
      </SectionFourMiddleItem>
      <SectionFourMiddleItem>
        <SectionFourMiddleItemTop>
          <h3>Conexão Minas</h3>
          <p>Zona da mata mineira, sul e norte de minas</p>
        </SectionFourMiddleItemTop>
        <SectionFourMiddleItemMiddle>
          <StaticImage
            src="../../../../images/paginas/conheca-a-generoso/section-four-item-three.png"
            alt="Imagem de conexão entre Transporte Generoso e Paulineris"
            quality={100}
            placeholder="blurred"
          />
        </SectionFourMiddleItemMiddle>
        <SectionFourMiddleItemBottom>
          <p>Ponto de conexão</p>
        </SectionFourMiddleItemBottom>
      </SectionFourMiddleItem>
    </SectionFourMiddle>
  );
};

export default SectionFourMiddleComponent;
