import React from 'react';

import { HeroSection } from './styles';

import { StaticImage } from 'gatsby-plugin-image';

import Logo from '@/images/paginas/conheca-a-generoso/logo.svg';

const HeroSectionComponent = (): JSX.Element => {
  return (
    <HeroSection>
      <StaticImage
        src="../../../../images/paginas/conheca-a-generoso/top-main-bg.jpg"
        alt="Imagem de caminhões"
        quality={100}
        placeholder="blurred"
      />
      <img src={Logo} alt="Logo do transporte Generoso" />
    </HeroSection>
  );
};

export default HeroSectionComponent;
