import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import {
  SectionOne,
  SectionOneLeft,
  SectionOneLeftBottom,
  SectionOneLeftMiddle,
  SectionOneLeftTop,
  SectionOneRight,
} from './styles';

const SectionOneComponent = (): JSX.Element => {
  return (
    <SectionOne>
      <SectionOneLeft>
        <SectionOneLeftTop>
          <h2>Atitudes que transformam</h2>
        </SectionOneLeftTop>

        <SectionOneLeftMiddle>
          <h3>&quot;Eu nunca vou parar de sonhar&quot; </h3>
          <p>Vivaldo Moreira Ramos</p>
          <p>Presidente do Transporte Generoso</p>

          <StaticImage
            src="../../../../images/paginas/conheca-a-generoso/section-one-hidden-one.png"
            alt="Presidente do Transporte Generoso"
            quality={100}
            placeholder="blurred"
          />
        </SectionOneLeftMiddle>

        <SectionOneLeftBottom>
          <div>
            <p>
              Em 02 de janeiro de 1980 um jovem sonhador filho de caminhoneiro,
              fundou o Transporte Generoso, na cidade de Barra Mansa, interior
              do Rio de Janeiro. Com a certeza de que uma empresa de valor é
              construída por pessoas honestas e focadas nas necessidades do
              cliente.
            </p>

            <p>
              Com trabalho duro, dedicação e confiança de todos os envolvidos
              conquistamos espaço em grande parte do território nacional,
              seguimos expandindo e investindo para que nossos clientes tenham
              todas as facilidades e condições para obterem êxito em seus
              segmentos.
            </p>

            <p>
              A Generoso segue pronta para honrar com os compromissos firmados e
              entregar todos os dias um serviço de alta qualidade. Somos movidos
              por sonhos e atitudes. SEJA GENEROSO!
            </p>
          </div>

          <StaticImage
            src="../../../../images/paginas/conheca-a-generoso/section-one-hidden-two.png"
            alt="Imagem antiga do início do Tranporte Generoso"
            quality={100}
            placeholder="blurred"
          />

          <StaticImage
            src="../../../../images/paginas/conheca-a-generoso/section-one-hidden-three.png"
            alt="Imagem do armazém do Transporte Generoso"
            quality={100}
            placeholder="blurred"
          />
        </SectionOneLeftBottom>
      </SectionOneLeft>

      <SectionOneRight>
        <StaticImage
          src="../../../../images/paginas/conheca-a-generoso/section-one.png"
          alt="Diversas imagens que mostram a história do Tranporte Generoso"
          quality={100}
          placeholder="blurred"
        />
      </SectionOneRight>
    </SectionOne>
  );
};

export default SectionOneComponent;
