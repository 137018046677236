/* eslint-disable */
/* prettier-ignore */
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import SectionFourMiddle from '@/components/Pages/SobreNos/SectionFourMiddle';

import Carvalima from '@/images/paginas/conheca-a-generoso/carvalima.svg';
import Paulineris from '@/images/paginas/conheca-a-generoso/paulineris.svg';
import Rodonaves from '@/images/paginas/conheca-a-generoso/rodonaves.svg';

import { SectionFour, SectionFourTop, SectionFourBottom } from './styles';

const SectionFourComponent = (): JSX.Element => {
  return (
    <SectionFour>
      <StaticImage
        src="../../../../images/paginas/conheca-a-generoso/nossa-jornada.png"
        alt="Jornada do Transporte Generoso"
        quality={100}
        placeholder="blurred"
        className="jornada"
      />

      <SectionFourTop>
        <h2>Aliança</h2>

        <p>
          Desde 2016 a Generoso vem se fortalecendo e ampliando sua atuação em
          parceria com os 3 principais players do segmento do transporte
          regional.
        </p>

        <div>
          <img src={Rodonaves} alt="Logo da Rodonaves" />
          <img src={Carvalima} alt="Logo da Carvalima" />
          <img src={Paulineris} alt="Logo da Paulineris" />
        </div>
      </SectionFourTop>

      <SectionFourMiddle />

      <SectionFourBottom>
        <a href="https://cliente.generoso.com.br/unidades" target='_blank' rel="noreferrer">Conheça nossas unidades</a>
      </SectionFourBottom>
    </SectionFour>
  );
};

export default SectionFourComponent;
