import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import {
  SectionFive,
  SectionFiveLeft,
  SectionFiveRight,
  SectionFiveRightText,
} from './styles';

const SectionFiveComponent = (): JSX.Element => {
  return (
    <SectionFive>
      <SectionFiveLeft>
        <StaticImage
          src="../../../../images/paginas/conheca-a-generoso/section-five.png"
          alt="Homem segurando caixas"
          quality={100}
          placeholder="blurred"
          height={770}
        />
      </SectionFiveLeft>
      <SectionFiveRight>
        <SectionFiveRightText>
          <h2>Nossas diretrizes</h2>
          <div>
            <h3>Missão</h3>
            <p>
              Transportar as mercadorias entregando no menor prazo, com
              informação rápida e precisa.
            </p>
          </div>
          <div>
            <h3>Visão</h3>
            <p>
              Ser reconhecida pelo mercado pela excelência dos serviços,
              agilidade, competitividade e parceria com seus clientes.
            </p>
          </div>
          <div>
            <h3>Valores</h3>
            <p>
              Generosidade, comprometimento, competência, foco no resultado,
              intolerância ao desperdício e ética.
            </p>
          </div>
        </SectionFiveRightText>
      </SectionFiveRight>
    </SectionFive>
  );
};

export default SectionFiveComponent;
