import styled from 'styled-components';

export const SectionTwo = styled.section`
  width: 100%;
  max-width: 1380px;

  display: flex;
  justify-content: center;

  margin-top: 77px;

  @media (max-width: 1440px) {
    margin-left: 29px;
    margin-right: 29px;
  }

  @media (max-width: 499px) {
    margin: 50px 16px 0;
  }
`;
