import React from 'react';

import { SectionTwo } from './styles';

import CompanyNumbers from '@/components/CompanyNumbers';

const SectionTwoComponent = (): JSX.Element => {
  return (
    <SectionTwo>
      <CompanyNumbers />
    </SectionTwo>
  );
};

export default SectionTwoComponent;
