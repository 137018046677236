import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const SectionOne = styled.section`
  width: 100%;
  max-width: 1174px;

  margin-top: 126px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1440px) {
    margin-left: 29px;
    margin-right: 29px;
  }

  @media (max-width: 1138px) {
    justify-content: center;
    margin-top: 30px;
  }

  @media (max-width: 500px) {
    margin: 32px 16px 0;
  }
`;

export const SectionOneLeft = styled.div``;

export const SectionOneLeftTop = styled.div`
  h2 {
    color: ${colors.blue400};
    font-size: 48px;
    line-height: 60px;

    width: 100%;
    max-width: 334px;
  }

  @media (max-width: 499px) {
    h2 {
      font-size: 30px;
      line-height: 42px;
    }
  }
`;

export const SectionOneLeftMiddle = styled.div`
  margin-top: 32px;
  h3 {
    font-size: 32px;
    line-height: 39px;

    font-weight: 600;
    font-style: italic;

    width: 100%;
    max-width: 286px;

    color: ${colors.blue300};
  }

  p {
    width: 100%;
    border-left: 1px solid ${colors.gray500};
    max-width: 286px;

    font-size: 14px;
    line-height: 18px;

    color: ${colors.blue300};

    padding-left: 15px;

    box-sizing: border-box;

    margin-top: 15px;

    & + p {
      margin-top: 0;
    }
  }

  .gatsby-image-wrapper {
    display: none;
  }

  @media (max-width: 1138px) {
    .gatsby-image-wrapper {
      display: block;
      margin-top: 32px;
    }
    img {
      border-radius: 8.4px;
    }
  }

  @media (max-width: 499px) {
    margin-top: 16px;

    h3 {
      font-size: 22px;
      line-height: 29px;
    }
    p {
      font-size: 10px;
      line-height: 14px;
    }
  }
`;

export const SectionOneLeftBottom = styled.div`
  p {
    margin-top: 32px;

    font-size: 16px;
    line-height: 24px;

    width: 100%;
    max-width: 464px;
  }

  .gatsby-image-wrapper {
    display: none;
  }

  @media (max-width: 1138px) {
    .gatsby-image-wrapper {
      display: block;
      margin-top: 17px;
    }
    img {
      border-radius: 8.4px;
    }
  }

  @media (max-width: 499px) {
    p {
      font-size: 10px;
      line-height: 14px;

      margin-top: 16px;
    }
  }
`;

export const SectionOneRight = styled.div`
  @media (max-width: 1138px) {
    display: none;
  }
`;
