import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const SectionFourMiddle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SectionFourMiddleItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  text-align: center;

  height: 100%;

  margin-top: 16px;

  width: 100%;

  padding: 25px 0;

  box-sizing: border-box;

  border: 1px solid ${colors.gray200};
  border-radius: 8.4px;

  background-color: ${colors.gray100};

  &:first-child {
    margin-top: 45px;
  }

  @media (max-width: 500px) {
    height: 130px;
    padding: 0;

    background-color: white;
    border: none;

    margin-top: 41px;
  }
`;

export const SectionFourMiddleItemTop = styled.div`
  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;

    color: ${colors.blue300};
  }

  p {
    text-transform: uppercase;
  }

  @media (max-width: 500px) {
    p {
      font-size: 10px;
      line-height: 10px;
    }
    h3 {
      font-size: 16px;
      line-height: 25px;
    }

    margin: 0;
  }
`;

export const SectionFourMiddleItemMiddle = styled.div`
  .gatsby-image-wrapper {
    height: 80px;
    width: 100%;
  }

  margin-top: 50px;

  @media (max-width: 500px) {
    margin: 0;
  }
  @media (max-width: 499px) {
    img {
      width: 100%;
    }
  }
`;

export const SectionFourMiddleItemBottom = styled.div`
  margin-top: 14px;
  p {
    font-weight: 500;
  }

  @media (max-width: 500px) {
    p {
      font-size: 10px;
      line-height: 10px;
    }
  }
`;
